import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { BOOL } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

// Note: Consolidates sections from multiple forms
export const formSections = [
    {
        title: "Graduation Information",
        id: "graduation-information",
        questions: [
            {
                title: "Graduation Term",
                id: "graduation-information-term",
                dataField: "termCode",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.term,
                filter: {
                    showFromPresent: 2
                }
            },
            {
                title: "Graduation Level",
                id: "graduation-information-level",
                dataField: "gradLevel",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.graduationLevel, 
                branch: "graduation-information-plan"
            },
            {
                title: "Graduation Plan",
                id: "graduation-information-plan",
                dataField: "academicPlan",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.graduatePrograms,
                filter: [{
                    "field":  "graduation-information-level",
                    "match": "gradLevel",
                    operation: "includes"
                }],
                results: [{
                    label: "Degree",
                    field: "degree",
                    dataField: "gradDegree"
                }],
                dependencyHelperText: "Graduation Level must be selected before choosing a Graduation Plan",
                dependencies: ["graduation-information-level"]
            }
        ]
    },
    {
        id: "oral-portion",
        title: "Oral portion of final examination",
        summary: (
            <Fragment>
                <Mui.Typography variant="body2" className="margin-bottom-2">
                Please note that if you will conduct a presentation of your
                dissertation research before the final oral examination, only indicate
                the time frame of the actual defense on this form.
                </Mui.Typography>
                <Mui.Typography variant="body2" className="margin-bottom-2">
                The final oral examination must be on a University business day and
                must begin no earlier than 7:30 AM, but end no later than 5:30 PM, and
                must be at least two hours in length.
                </Mui.Typography>
            </Fragment>
        ),
        questions: [
            {
                id: "date-input-group",
                title: "Date of Oral Examination",
                type: inputMappings.datetime,
                dataField: "oralDate",
                required: false,
                startTime: true,
                endTime: true,
                validation: {
                    begin: "7:30 AM",
                    days: 14,
                    end: "5:30 PM",
                    durationInHours: 2,
                    weekend: true
                }
            },
            {
                id: "video-conf-component",
                type: inputMappings.checkbox,
                defaultValue: BOOL.NO,
                dataField: "videoConference",
                choices: [{
                    id: "yes-video-conf-component",
                    value: BOOL.YES,
                    title: "There will be a video conference component to this examination.",
                    branch: "video-conf-platform"
                }]
            },
            {
                id: "video-conf-platform",
                title: "Video Conference Platform (example: Zoom meeting, Microsoft Teams, Skype, etc.)",
                dependencies: ["video-conf-component"],
                dataField: "videoConferencePlatform",
                type: inputMappings.textInput,
                required: false,
                summary: (
                    <Fragment>
                        <Mui.Typography variant="body2" className="margin-bottom-2">For examinations that are entirely virtual you do not need to include room and building.</Mui.Typography>
                        <Mui.Typography variant="body2">If your examination is both virtual and in person please include room and building.</Mui.Typography>
                    </Fragment>
                ),
                inputProps: {
                    maxLength: 255
                }
            },
            {
                id: "room-number-building",
                type: inputMappings.inputGroup,
                numberOfResponsesMaximum: 1,
                questions: [
                    {
                        id: "room-num",
                        title: "Room Number",
                        dataField: "roomNumber",
                        type: inputMappings.textInput,
                        inputProps: {
                            maxLength: 255
                        }
                    },
                    {
                        id: "building",
                        title: "Building",
                        dataField: "building",
                        type: inputMappings.textInput,
                        inputProps: {
                            maxLength: 255
                        }
                    }
                ]
            }
        ]
    },
    {
        title: "Committee",
        id: "committee",
        questions: [
            {
                id: "advisor-subheading",
                type: inputMappings.subheading,
                title: "Advisor Information"
            },
            {
                id: "committee-advisor",
                type: inputMappings.dropDown,
                dataField: "committee.advisor",
                title: "Advisor",
                summary: null,
                required: false,
                subtype: dropdownSubtypes.advisor,
                dependencies: ["graduation-information-plan"],
                dependencyHelperText: "Graduation Plan must be selected before choosing an Advisor"
            },
            {
                id: "co-advisor",
                type: inputMappings.dropDown,
                title: "Co-Advisor (if applicable)",
                subtype: dropdownSubtypes.coAdvisor,
                summary: null,
                required: false,
                dataField: "committee.coAdvisor"
            },
            {
                id: "committee-members",
                type: inputMappings.inputGroup,
                title: "Committee Members",
                subtitle: "Graduate Faculty committee members",
                summary: "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
                required: false,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 4,
                dependencies: [],
                choices: [],
                dataField: "committee.members",
                questions: [{
                    id: "committee-member-info",
                    type: inputMappings.dropDown,
                    subtype: dropdownSubtypes.facultyMember,
                    title: "Faculty Member",
                    summary: null,
                    required: true
                }]
            },
            {
                id: "external-members",
                type: inputMappings.inputGroup,
                title: "External Members",
                subtitle: "Non-Graduate Faculty committee members",
                summary: "For each external committee member, completion & submission of Committee and Examination Petition is required.",
                required: false,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 4,
                dependencies: [],
                choices: [],
                dataField: "committee.externalMembers",
                questions: [
                    {
                        id: "first-name",
                        type: inputMappings.textInput,
                        title: "First Name",
                        dataField: "firstName",
                        summary: null,
                        required: true,
                        numberOfResponsesMinimum: 1,
                        numberOfResponsesMaximum: 1,
                        dependencies: [],
                        choices: []
                    },
                    {
                        id: "last-name",
                        type: inputMappings.textInput,
                        title: "Last Name",
                        summary: null,
                        required: true,
                        dataField: "lastName",
                        numberOfResponsesMinimum: 1,
                        numberOfResponsesMaximum: 1,
                        dependencies: [],
                        choices: []
                    },
                    {
                        id: "email",
                        type: inputMappings.email,
                        title: "Email Address",
                        dataField: "email",
                        summary: null,
                        required: true,
                        numberOfResponsesMinimum: 1,
                        numberOfResponsesMaximum: 1,
                        dependencies: [],
                        choices: []
                    }
                ]
            },
            {
                id: "grad-faculty-rep",
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.gradFacultyRepresentative,
                dataField: "committee.gradFacultyRepresentative",
                title: "Graduate Faculty Representative",
                required: false
            },
            {
                id: "category-m-approved",
                type: inputMappings.checkbox,
                dataField: "categoryMApproved",
                defaultValue: BOOL.NO,
                choices: [{
                    id: "category-m-approved-yes",
                    title: "Category M Approved",
                    value: BOOL.YES
                }]
            }
        ]
    }
];