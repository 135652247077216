import React, { useEffect, useRef } from "react";
import Main from '../../Main/components'
import { EXTERNAL_LINK_DESCRIPTOR, MAIN_ID, OPENS_NEW_WINDOW_DESCRIPTOR } from "../../util/constants";
import Footer from "@osu/footer";
import { withOSUTheming, useScrollToHash, Mui, withTableOfContents, useFooterWithTOC, withDarkMode } from '@osu/react-ui';
import { AppProvider } from "../context";
import { navOverrideStyles } from "../overrides";
import { Navbar as OSUNavBar } from "@osu/navbar";
import useGridSizing from "../../Dashboard/hooks/useGridSizing";
import Navbar from "../../Navbar/containers";
import { mergeSxStyles } from "../../styles/util";
import withAuthentication from "../../Authentication/withAuthentication";
import useCssVariableUpdater from "../hooks/useCssVariableUpdater";
import { Hub } from "aws-amplify/utils";

function App(props = {}) {
  const { navHeight, darkMode } = props
  const { ref } = useScrollToHash()
  const footerRef = useRef()
  useFooterWithTOC({ ref: footerRef })
  const { padding } = useGridSizing()
  const trigger = Mui.useScrollTrigger({
    disableHysteresis: true,
    threshold: navHeight
  });

  const sx = mergeSxStyles(
    (theme) => navOverrideStyles(theme, padding),
    (theme)=> ({
      [` .${Mui.linkClasses.root}`]: {
        fontWeight: theme.typography.fontWeightBold
      }
    })
  );
  
  return (
    <Mui.Box className="App" ref={ref} sx={sx}>
      <OSUNavBar darkMode={darkMode} />
      <Navbar />
      <Main className="anchor-link" spacingTop={!trigger} id={MAIN_ID} />
      <Footer ref={footerRef} />
      <Mui.Typography id={EXTERNAL_LINK_DESCRIPTOR} aria-hidden variant="srOnly">&nbsp;(link is external)</Mui.Typography>
      <Mui.Typography id={OPENS_NEW_WINDOW_DESCRIPTOR} aria-hidden variant="srOnly">&nbsp;(opens new window)</Mui.Typography>
    </Mui.Box>
  );
}

const TOCWrappedApp = withTableOfContents(App)

function Provider(props = {}) {
  const { authorized, handleSignIn, authStatus, handleSignOut, darkMode, external, isLoggedIn } = props;
  const { navHeight } = useCssVariableUpdater()
  const appContext = {
    authStatus,
    authorized,
    handleSignIn,
    handleSignOut,
    navHeight,
    darkMode,
    external,
    isLoggedIn
  }

  
  return (
    <AppProvider value={appContext}>
      <TOCWrappedApp darkMode={darkMode} navHeight={navHeight} />
    </AppProvider>
  );
}

export default withDarkMode(withOSUTheming(withAuthentication(Provider)))