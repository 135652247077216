import React from "react";
import { connect, useSelector } from "react-redux";
import { FORCED_AUTHENTICATION } from "../util/constants";
import useAmplifyHub from "../Common/hooks/useAmplifyHub";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";

const TIMEOUT = 60000 * (parseInt(process.env.REACT_APP_TIMEOUT) || 120);

function withAuthentication(Component) {
  function _withAuthentication(props = {}) {
    const { status, ...rest } = props
    const navigate = useNavigate()
    const isExternalUser = useSelector(state => {
      const externalUser = state.authentication?.user?.externalUserId
      return !!externalUser
    })
    const { handleSignOut, handleSignIn: amplifySignIn } = useAmplifyHub();
    const authorized = FORCED_AUTHENTICATION ? status === 'success' : true

    const handleSignIn = (redirectPath) => {
      // debugger;
      console.log("jmm - isExternalUser", isExternalUser);
      if(isExternalUser) {
        //do something else
        //run validations
      } else {
        amplifySignIn(redirectPath)
      }
    }

    const handleOnIdle = (event) => {
      console.log("user is idle", event);
      console.log("last active", getLastActiveTime());
      navigate('/logout')
    };
    const { getLastActiveTime } = useIdleTimer({
      timeout: TIMEOUT,
      onIdle: handleOnIdle,
    });
    
    
    return <Component handleSignIn={handleSignIn} handleSignOut={handleSignOut} authorized={authorized} {...rest} />
  }

  const mapStateToProps = (state) => {
    return {
      status: state.authentication?.status,
      authentication: state.authentication,
    };
  };

  return connect(mapStateToProps, undefined)(_withAuthentication);
}

export default withAuthentication;