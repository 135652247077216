import { dropdownSubtypes, inputMappings } from "../../util/enums";

// Note: Consolidates sections from multiple forms
export const formSections = [
    {
        title: "Graduation Information",
        id: "graduation-information",
        questions: [
            {
                title: "Graduation Term",
                id: "graduation-information-term",
                dataField: "termCode",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.term,
                filter: {
                    showFromPresent: 2
                }
            },
            {
                title: "Graduation Level",
                id: "graduation-information-level",
                dataField: "gradLevel",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.graduationLevel, 
                branch: "graduation-information-plan"
            },
            {
                title: "Graduation Plan",
                id: "graduation-information-plan",
                dataField: "academicPlan",
                required: true,
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.graduatePrograms,
                filter: [{
                    "field":  "graduation-information-level",
                    "match": "gradLevel",
                    operation: "includes"
                }],
                results: [{
                    label: "Degree",
                    field: "degree",
                    dataField: "gradDegree"
                }],
                dependencyHelperText: "Graduation Level must be selected before choosing a Graduation Plan",
                dependencies: ["graduation-information-level"]
            }
        ]
    },
    {
        title: "Committee",
        id: "committee",
        questions: [
            {
                id: "advisor-subheading",
                type: inputMappings.subheading,
                title: "Advisor Information"
            },
            {
                id: "committee-advisor",
                type: inputMappings.dropDown,
                dataField: "committee.advisor",
                title: "Advisor",
                summary: null,
                required: false,
                subtype: dropdownSubtypes.advisor,
                dependencies: ["graduation-information-plan"],
                dependencyHelperText: "Graduation Plan must be selected before choosing an Advisor"
            },
            {
                id: "co-advisor",
                type: inputMappings.dropDown,
                title: "Co-Advisor (if applicable)",
                subtype: dropdownSubtypes.coAdvisor,
                summary: null,
                required: false,
                dataField: "committee.coAdvisor"
            },
            {
                id: "committee-members",
                type: inputMappings.inputGroup,
                title: "Committee Members",
                subtitle: "Graduate Faculty committee members",
                summary: "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
                required: false,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 4,
                dependencies: [],
                choices: [],
                dataField: "committee.members",
                questions: [{
                    id: "committee-member-info",
                    type: inputMappings.dropDown,
                    subtype: dropdownSubtypes.facultyMember,
                    title: "Faculty Member",
                    summary: null,
                    required: true
                }]
            },
            {
                id: "external-members",
                type: inputMappings.inputGroup,
                title: "External Members",
                subtitle: "Non-Graduate Faculty committee members",
                summary: "For each external committee member, completion & submission of Committee and Examination Petition is required.",
                required: false,
                numberOfResponsesMinimum: 1,
                numberOfResponsesMaximum: 4,
                dependencies: [],
                choices: [],
                dataField: "committee.externalMembers",
                questions: [
                    {
                        id: "first-name",
                        type: inputMappings.textInput,
                        title: "First Name",
                        dataField: "firstName",
                        summary: null,
                        required: true,
                        numberOfResponsesMinimum: 1,
                        numberOfResponsesMaximum: 1,
                        dependencies: [],
                        choices: []
                    },
                    {
                        id: "last-name",
                        type: inputMappings.textInput,
                        title: "Last Name",
                        summary: null,
                        required: true,
                        dataField: "lastName",
                        numberOfResponsesMinimum: 1,
                        numberOfResponsesMaximum: 1,
                        dependencies: [],
                        choices: []
                    },
                    {
                        id: "email",
                        type: inputMappings.email,
                        title: "Email Address",
                        dataField: "email",
                        summary: null,
                        required: true,
                        numberOfResponsesMinimum: 1,
                        numberOfResponsesMaximum: 1,
                        dependencies: [],
                        choices: []
                    }
                ]
            },
            {
                id: "grad-faculty-rep",
                type: inputMappings.dropDown,
                subtype: dropdownSubtypes.gradFacultyRepresentative,
                dataField: "committee.gradFacultyRepresentative",
                title: "Graduate Faculty Representative",
                required: false
            }
        ]
    }
];