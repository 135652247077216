import React, { Fragment, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Helmet, PageHeadingContext } from "@osu/react-ui";
import { AppContext } from '../../App/context';
import { Navigate, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import { LOGIN_ROUTE, LOGIN_ROUTE_EXTERNAL, UNAUTHORIZED_ROUTE } from '../paths';

export function RenderableView({ pathInformation = {props: {}, noIndex: false, authRequired: false} }) {
    const { props = {}, Component, noIndex, title: pathTitle, titleProps } = pathInformation
    const { setHeading, setOptions } = useContext(PageHeadingContext)
    const location = useLocation();
    const match = useMatch(location.pathname);
    const params = useParams();
    match.params = params;
    const navigate = useNavigate();
    const renderProps = { location, match, navigate};
    const title = typeof pathTitle === 'function' ? pathTitle(renderProps) : pathTitle
    const { authorized, isLoggedIn, external } = useContext(AppContext)
    const authRequired = pathInformation.authRequired
    const redirectPath = location?.pathname ?? pathInformation?.path
    
    useEffect(() => {    
        setHeading(title)
    }, [setHeading, title])

    useEffect(() => {    
        const options = Object.assign({ component: 'h1', variant: 'h2', sx: { fontWeight: 700, marginBottom: "1rem" }}, titleProps)

        setOptions(options)
    }, [setOptions, titleProps])

    if(!isLoggedIn) {
        return <Navigate to={external ? LOGIN_ROUTE_EXTERNAL : LOGIN_ROUTE} state={{
            redirectPath
        }} />
    }
    if(!authorized) {
        return <Navigate to={UNAUTHORIZED_ROUTE} />
    }

    return <Fragment>
        {(noIndex || authRequired) && <Helmet>
            <meta id="meta-hidden" name="robots" content="noindex" />
        </Helmet>}
        <Component 
            {...renderProps}  
            {...props} 
        />
    </Fragment>
}

RenderableView.propTypes = {
    pathInformation: PropTypes.shape({
        path: PropTypes.string.isRequired, 
        props: PropTypes.object, 
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired, 
        titleProps: PropTypes.object, 
        Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.object]).isRequired, 
        noIndex: PropTypes.bool, 
        authRequired: PropTypes.bool
    }).isRequired, 
    createTitle: PropTypes.func,
}

export default RenderableView