import React, { useContext, useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Mui, PageHeadingContext } from "@osu/react-ui";
import { AppContext } from "../../App/context";
import { ACTION_STATUS } from "../../util/constants";

export default function Unauthorized(props = {}) {
    const { redirectPath } = props;
    const { setHeading } = useContext(PageHeadingContext)
    const { authStatus } = useContext(AppContext);
    const location = useLocation();

    useEffect(() => {
        if(authStatus === ACTION_STATUS.UNAUTHORIZED) setHeading && setHeading("Unauthorized");
    }, [authStatus, setHeading]);
    
    if(![ACTION_STATUS.SUCCESS, ACTION_STATUS.UNAUTHORIZED].includes(authStatus)) {
        return <Navigate to='/login' state={{ redirectPath: location?.state?.redirectPath || redirectPath }} />
    }

    if(authStatus === ACTION_STATUS.UNAUTHORIZED) {
        return (
            <span>
                You are not authorized to view this application. If you believe this to be in error, 
                contact the{" "}<a href="mailto:oasis-support@lists.osu.edu">Web and Mobile Team</a>{" "}for assistance.
            </span>
        );
    }

    return (
        <Mui.Stack direction="column" spacing={2}>
            <Mui.Typography>You are not authorized to see this page.</Mui.Typography>
            <Mui.Box>
                <Mui.Button component={Link} to="/" sx={{ textTransform: "none" }}>Go Home</Mui.Button>
            </Mui.Box>
        </Mui.Stack>
    );
}