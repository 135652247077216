import { fetchAuthSession } from "aws-amplify/auth";

const libraryOptions = {
    API: {
        GraphQL: {
            headers: async () => {     ///might need to add things for external users here like useSelector to get authentication user state
                let authorizationValue = {};
                try {
                    const authSession = await fetchAuthSession();
                    const idToken = authSession?.tokens?.idToken;
                    if(idToken) authorizationValue = idToken.toString();
                } catch (error) {
                    console.error("GraphQL Headers Error:", error);
                }
                return { Authorization: authorizationValue };
            }
        }
    }
};

export default libraryOptions;